export const BOAT_LOANS_URL = '/boat-loans/';
export const BOAT_LOANS = 'boat-loans';
export const BOAT_LOANS_RATES_URL = '/boat-loans/rates/';

export const CDN_URL = `${process.env.REACT_APP_CDN_URL}/${BOAT_LOANS}`;
export const RATES_TRIDENT_API_URL = `${process.env.REACT_APP_TRIDENT_API_URL}/rates/0/0/Boat?loanType=new`;
export const CALCULATOR_TRIDENT_API_URL = `${process.env.REACT_APP_TRIDENT_API_URL}/rates-calculator/0/0/Boat`;
export const GREEN_CHECKMARK_ICON = `${CDN_URL}/green-checkmark.png`;

export const RATE_DISCLAIMER = '*Estimated APR, or Annual Percentage Rate, is the annual cost of the loan, which includes other charges and fees. Actual rate and term can vary depending on credit history, collateral type, age of collateral, loan amount, loan term, loan to value, vessel registration type (LLC/Trust), state of residency and other criteria. Rates and terms may vary and are subject to change without notice.';
export const PERKS_LIST_DESKTOP = ['Low monthly payments', 'Competitive boat loan rates', 'No payment for up to 60 days', 'New and used boat loans', 'Partnering with Trident Funding'];
export const HERO_BACKGROUND = '/boat-loan-hero.png';
export const HERO_BACKGROUND_V2 = '/boat-loan-hero-v2.webp';

export const CALCULATOR_SMALL_PRINT = `${RATE_DISCLAIMER} Credit score can vary depending on the credit reporting agency. Payment example: A $76,000 loan for the purchase of a recreational use vessel for 20 years with a fixed rate of 5.49% would have an estimated APR of 5.49% and 240 payments of $522.37.`;
export const MAX_AGE_TO_PREQUALIFY = 20;
export const MIN_PRICE_TO_PREQUALIFY = 10000;
export const BOAT_LEAD_PURCHASE_TYPE = 'boatPurchase';
export const BOAT_LEAD_SERVICES_TYPE = 'boatServices';

export const MAX_AGE_TO_FINANCE = 20;
export const MIN_PRICE_TO_FINANCE = 10000;
export const DOWN_PAYMENT_PERCENTAGE = 20;

export const TRACKING_EVENT_LEAD_PURCHASE_SUBMISSION = 'boat loan lead submission';
export const TRACKING_EVENT_LEAD_SERVICES_SUBMISSION = 'services lead submission';
export const TRACKING_EVENT_LEAD_SERVICES_SUBMIT = 'services form submit';
export const TRACKING_EVENT_LEAD_SERVICES_TOP_PAGE_SUBMIT = 'get protected services page top';
export const TRACKING_EVENT_LEAD_SERVICES_BOTTOM_PAGE_SUBMIT = 'get protected services page bottom';

export const TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_TYPE = 'services form submit';
export const TRACKING_ROAD_ASSISTANCE_TOP_FORM_ACTION_LABEL = 'get protected roadside assistance plan page top';
export const TRACKING_ROAD_ASSISTANCE_BOTTOM_FORM_ACTION_LABEL = 'get protected roadside assistance plan page bottom';
export const TRACKING_ROAD_ASSISTANCE_TOP_FORM_EVENT = 'services lead submission';

export const TRACKING_TIRE_WHEEL_TOP_FORM_ACTION_TYPE = 'services form submit';
export const TRACKING_TIRE_WHEEL_TOP_FORM_ACTION_LABEL = 'get protected tire & wheel page top';
export const TRACKING_TIRE_WHEEL_TOP_FORM_EVENT = 'services lead submission';
export const TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_ACTION_TYPE = 'services form submit';
export const TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_ACTION_LABEL = 'get protected tire and wheel page bottom';
export const TRACKING_TIRE_WHEEL_INFO_SECTION_FORM_EVENT = 'services lead submission';

export const TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_TYPE = 'services form submit';
export const TRACKING_GAP_PROTECTION_TOP_FORM_ACTION_LABEL = 'get protected gap protection plan page top';
export const TRACKING_GAP_PROTECTION_BOTTOM_FORM_ACTION_LABEL = 'get protected gap protection plan page bottom';
export const TRACKING_GAP_PROTECTION_TOP_FORM_EVENT = 'services lead submission';

export const CDN_RATES_URL = `${process.env.REACT_APP_CDN_URL}/boat-loans-rates`;
export const BOAT_LOANS_RATES_HERO_BACKGROUND = '/boat-loans-rates-hero.webp';
export const BOAT_LOANS_RATES_INFO_1 = '/factors-affecting-boat-loan-interest-rates.jpeg';
export const BOAT_LOANS_RATES_INFO_2 = '/boat-loan-rates.jpeg';
export const BOAT_LOANS_RATES_INFO_3 = '/tips-securing-best-loan-rate.jpg';
export const RATES_CALCULATOR_SMALL_PRINT = '*Estimated APR, or Annual Percentage Rate, is the annual cost of the loan, which includes other charges and fees. Actual rate and term can vary depending on credit history, collateral type, age of collateral, loan amount, loan term, loan to value, vessel registration type (LLC/Trust), state of residency and other criteria. Rates and terms may vary and are subject to change without notice. Credit score can vary depending on the credit reporting agency. Payment example: A $76,000 loan for the purchase of a recreational use vessel for 20 years with a fixed rate of 5.49% would have an estimated APR of 5.49% and 240 payments of $522.37.';

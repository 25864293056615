import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BOATS_ID } from '../../../constants/home';
import { getDesktopMobileBreakpoint, BREAKPOINTS } from '../../../utils/commonHelper';
import { getOrganizationSchema, getWebsiteSchema } from '../../../utils/metaContentHelper';
import ArticlesAndReviews from '../ArticlesAndReviews';
import BoatsNearYou from '../BoatsNearYou';
import FSBOBanner from '../FSBOBanner';
import HeroServerAd from '../HeroServerAd';
import PopularBoats from '../PopularBoats';
import SearchSubmission from '../SearchSubmission';
import CompanionServerAd from '../CompanionServerAd';
import { matchAdName } from '../../../utils/ads/adsUtils';
import './styles.css';

const { desktop } = BREAKPOINTS;

const MainContent = (props) => {
  const articlesAndReviews = get(props, 'home.articlesAndReviews', []);
  const popularBoats = get(props, 'home.popularBoats', []);
  const zipcode = get(props, 'home.zipcode', '');
  const radiusMiles = get(props, 'home.radiusMiles', 200);
  const coordinates = get(props, 'home.coordinates', []);
  const serverAds = props?.home?.ads;
  const heroServerAd = matchAdName(serverAds, 'home_hero');
  const homeCompanion = matchAdName(serverAds, 'home_companion');
  const homeCompantionStick = matchAdName(serverAds, 'home_companion-sticky-banner');


  const websiteSchema = <script type="application/ld+json">{JSON.stringify(getWebsiteSchema())}</script>;
  const organizationSchema = <script type="application/ld+json">{JSON.stringify(getOrganizationSchema())}</script>;

  return (
    <>
      <Helmet>
        {websiteSchema}
        {organizationSchema}
      </Helmet>
      <span className="nav-cover"></span>
      <div id="homepage">
        <div className="bt-inc-release">
          <section className="main-content home">
            <aside className="home-search">
              <SearchSubmission zipcode={zipcode} defaultTab={BOATS_ID} radiusMiles={radiusMiles}/>
              <FSBOBanner hasImage={false} />
            </aside>
            { !isEmpty(heroServerAd) && <HeroServerAd ad={heroServerAd} />}
          </section>

          <section id="recommended-boats" data-e2e="recommended-boats">
            <BoatsNearYou cookies={props.cookies} coordinates={coordinates}/>
          </section>

          { !isEmpty(homeCompanion) && <CompanionServerAd ad={homeCompanion} adKey="home_companion" /> }

          <ArticlesAndReviews posts={articlesAndReviews} />
          <PopularBoats posts={popularBoats} />
        </div>
      </div>
      { (desktop === getDesktopMobileBreakpoint()) && !isEmpty(homeCompantionStick) && <CompanionServerAd ad={homeCompantionStick} adKey="home_companion-sticky-banner" /> }
    </>
  );
};

export default MainContent;

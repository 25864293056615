import * as types from '../constants';
import filter from 'lodash/filter';
import get from 'lodash/get';
import omit from 'lodash/omit';

// In order to avoid a big refactor of the way redux works and the isWorking property,
// we are going to use this to fix renders related to the getDataRequest action.
export const MainRequest = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED'
};
export const initialState = {
  isWorking: false,
  componentWorking: [],
  success: false,
  errors: false,
  message: '',
  params: {},
  data: {},
  user: {userName: '', loggedIn: false},
  monthlyPrice: '',
  carouselClickCount: 0,
  carouselClickCountModal: 0,
  myboats: {
    requireLogin: true
  },
  userCountryCode: '',
  userRadiusMiles: '',
  userZipCode: '',
  userCountryCodeError: null,
  userRef: null,
  trident: {},
  // see comment on MainRequest declaration
  mainRequestState: MainRequest.IDLE,
  leadSuccess: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_DATA_REQUEST: {
    const newState = {
      ...state,
      ...omit(action, 'type'),
      isWorking: true,
      mainRequestState: MainRequest.LOADING,
      params: action.params || {}
    };
    return newState;
  }

  case types.BYPASSING_URL: {
    return { ...state, bypassUrl: action.url };
  }

  case types.BYPASSED_URL: {
    const newState = {...state};
    if (newState.bypassUrl) {
      delete newState.bypassUrl;
    }
    return { ...newState };
  }

  case types.GET_DATA_SUCCESS: {
    const newState = {
      ...state,
      mainRequestState: MainRequest.LOADED,
      ...omit(action, 'type'),
      data: {
        ...action.data
      },
    };
    return newState;
  }

  case types.GET_DATA_SUCCESS_HOME: {
    const newState = {
      ...state,
      mainRequestState: MainRequest.LOADED,
      data: {
        ...state.data,
        ...action.data
      },
    };
    return newState;
  }

  case types.IDLE_MAIN_REQUEST: {
    return {
      ...state,
      mainRequestState: MainRequest.IDLE
    };
  }

  case types.GET_FACET_REQUEST:
    return {
      ...state,
      ...omit(action, 'type'),
      componentWorking: [
        ...state.componentWorking,
        'facets'
      ],
      params: action.params || {}
    };
  case types.GET_FACET_SUCCESS:
    return {
      ...state,
      ...omit(action, 'type'),
      componentWorking: filter(state.componentWorking, (item) => item !== 'facets'),
      data: {
        ...state.data,
        facets: action.data.facets || []
      }
    };
  case types.GET_ENGINES_FACETS_REQUEST:
    return {
      ...state,
      ...omit(action, 'type'),
      componentWorking: [
        ...state.componentWorking,
        'facets'
      ],
      params: action.params || {}
    };
  case types.GET_ENGINES_FACETS_SUCCESS:
    return {
      ...state,
      ...omit(action, 'type'),
      componentWorking: filter(state.componentWorking, (item) => item !== 'facets'),
      data: {
        ...state.data,
        facets: action.data.facets || []
      }
    };
  case types.PRICE_PER_MONTH:
    return {
      ...state,
      ...omit(action, 'type'),
      monthlyPrice: action.data.monthlyPrice || '',
      data: {
        ...state.data,
      }
    };
  case types.GET_SORT_PARAM:
    return {
      ...state,
      sortBy: action.sortBy,
      ...omit(action, 'type'),
    };

  case types.GET_DATA_FAILURE:
    return {
      ...state,
      mainRequestState: MainRequest.FAILED,
      ...omit(action, 'type')
    };

  case types.LOGIN_USER:
    return {
      ...state,
      user: {
        userName: action.user.userName,
        loggedIn: true
      },
      myboats: {
        requireLogin: false,
        listings: get(state, 'myboats.listings', [])
      }
    };

  case types.LOGOUT_USER:
    return {
      ...state,
      user: initialState.user,
      myboats: {requireLogin: true, listings: []}
    };

  case types.SET_USER_REF_VALUE:
    return {
      ...state,
      userRef: action.userRef
    };

  case types.MYBOATS_LISTINGS_SUCCESS:
    return {
      ...state,
      myboats: {requireLogin: false, listings: action.myboats}
    };

  case types.MYBOATS_LISTINGS_FAILURE:
    return {
      ...state,
      myboats: {requireLogin: false, listings: []},

    };

  case types.MYBOATS_REQUIRE_LOGIN:
    return {
      ...state,
      myboats: {requireLogin: true, listings: []},
    };

  case types.INCREMENT_CAROUSEL_CLICKCOUNT:
    return {
      ...state,
      ...omit(action, 'type'),
      carouselClickCount: state.carouselClickCount + 1
    };

  case types.INCREMENT_CAROUSEL_MODAL_CLICKCOUNT:
    return {
      ...state,
      ...omit(action, 'type'),
      carouselClickCountModal: state.carouselClickCountModal + 1
    };

  case types.NEXT_PREVIOUS_DATA:
    return {
      ...state,
      navLinks: action.data
    };

  case types.SET_USER_COUNTRY_CODE:
    return {
      ...state,
      userCountryCode: action.data
    };

  case types.SET_USER_COUNTRY_CODE_ERROR:
    return {
      ...state,
      userCountryCodeError: action.data
    };

  case types.SET_USER_MARKET:
    return {
      ...state,
      userMarket: action.data
    };

  case types.SET_USER_RADIUS_MILES:
    return {
      ...state,
      userRadiusMiles: action.data
    };

  case types.SET_USER_ZIPCODE:
    return {
      ...state,
      userZipCode: action.data
    };

  case types.SET_USER_EMAIL:
    return {
      ...state,
      userEmail: action.data
    };

  case types.TRIDENT_SET_RATES:
    return {
      ...state,
      trident: {
        ...state.trident,
        rates: action.data,
      }
    };

  case types.TRIDENT_SET_BOAT_LOANS_CONTENT:
    return {
      ...state,
      trident: {
        ...state.trident,
        boatLoansSection: action.data,
      }
    };

  case types.TRIDENT_LEAD_SUBMITTED:
    return {
      ...state,
      trident: {
        ...state.trident,
        isLeadSubmitted: action.data,
      }
    };

  case types.SET_ADDITIONAL_LEADS_DATA:
    return {
      ...state,
      additionalLeads: {
        ...state.additionalLeads,
        leadData: action.data.leadData,
        listings: action.data.listings
      }
    };

  case types.LEAD_SUCCESS:
    return {
      ...state,
      leadSuccess: action.data
    };

  case types.TOGGLE_ADDITIONAL_LEADS_MODAL:
    return {
      ...state,
      additionalLeads: {
        ...state.additionalLeads,
        open: action.data
      }
    };

  case types.TOGGLE_ADDITIONAL_LEADS_ALERT:
    return {
      ...state,
      additionalLeads: {
        ...state.additionalLeads,
        alert: {
          open: action.data.open,
          success: action.data.success
        }
      }
    };

  case types.TOGGLE_SUBMISSION_SUCCESS_ALERT:
    return {
      ...state,
      additionalLeads: {
        alert: {
          isOem: action.data.isOem,
          open: action.data.open,
          success: action.data.success
        }
      }
    };

  case types.SET_CONTACT_FORM_MESSAGE:
    return {
      ...state,
      contactFormMessage: {
        ...state.contactFormMessage,
        type: action.data.type,
        message: action.data.message,
        success: action.data.success,
      }
    };

  case types.SHOW_POSTLEAD_MODAL:
    return {
      ...state,
      showPostLeadModal: action.data
    };

  default:
    return state;
  }

};

export default reducer;

import { Action } from '@dmm/lib-react-ui-components';
import React from 'react';
import { makeTrackingEvent, checkPreQualVariation } from '../../../utils/trident';
import './styles.css';

const FinanceBanner = () => {

  const onClickPreQual = () =>{
    const preQualTracking = makeTrackingEvent('Pre-Qualify', 'click');
    window.dataLayer.push(preQualTracking);

    checkPreQualVariation({utmContent: 'SRPBanner', utmCampaign: 'SRPBanner'});
  };


  return (
    <div className="finance-banner" id="finance-banner">
      <div className="info-contain">
        <div className="banner-top">
          <div className="banner-text">
            <div className="banner-title">Instantly Pre-Qualify for a boat loan</div>
            <div className="banner-para">
                    We&apos;ll check over 15 marine lenders to get you the best rate with the best terms.
            </div>
          </div>
        </div>
        <div className="banner-button">
          <Action as="a" stretch size="small" variant="secondary" className="banner-calc-button" href="https://www.boattrader.com/boat-loans/calculator/" target="_blank" rel="noreferrer" label="Loan Calculator" />
          <Action as="a" size="small" className="banner-prequal-button" onClick={onClickPreQual} rel="noreferrer" label="Pre-Qualify" />
        </div>
      </div>
    </div>
  );
};


export default FinanceBanner;

import React from 'react';
import { useDispatch } from 'react-redux';
import ServicesItem from '../../../components/ServicesItem';
import { Collapsible, CollapsibleContent, CollapsibleHeader } from '../../../components/Collapsible';
import { setCollapseContentTracking } from '../../../store/actions/dataLayer';
import ServicesAd from '../../../components/Ads/ServicesAd';
import ServerAd from '../../../components/ServerAd';
import {
  DOCUMENTATION_SERVICE,
  YACHTCLOSER_PROVIDER
} from '../../../constants/services';

import './styles.css';


const Services = ({useServerAds, serverAds}) => {
  const dispatch = useDispatch();

  return (
    <CollapsibleContent initialState="open" classOverride="details services-collapsible">
      <CollapsibleHeader onClick={
        (e,open) => {
          if (open) {
            dispatch(setCollapseContentTracking('services'));
          }
        }
      }>
        Other Services
      </CollapsibleHeader>
      <Collapsible type="no-pad">
        <div className="services">
          {useServerAds ?
            serverAds && (
              <>
                <ServerAd ad={serverAds['boat-details_service-button-1']} className={'service-item'} />
                <ServerAd ad={serverAds['boat-details_service-button-2']} className={'service-item'} />
              </>
            )
            :
            <>
              <ServicesAd name="shipping" />
              <ServicesAd name="Insurance" />
            </>
          }
          <ServicesItem
            link="/services/boat-documentation/"
            serviceTitle="Boat Documentation"
            serviceIcon="documentation"
            serviceType={DOCUMENTATION_SERVICE}
            serviceName={YACHTCLOSER_PROVIDER}
          />
          {useServerAds ?
            serverAds && (
              <>
                <ServerAd ad={serverAds['boat-details_service-button-3']} className={'service-item'}/>
                <ServerAd ad={serverAds['boat-details_service-button-4']} className={'service-item'}/>
                <ServerAd ad={serverAds['boat-details_service-button-5']} className={'service-item'}/>
              </>
            )
            :
            <>
              <ServicesAd name="ad-rental" />
              <ServicesAd name="ad-service-1"/>
              <ServicesAd name="ad-service-2"/>
            </>
          }
        </div>
      </Collapsible>
    </CollapsibleContent>
  );
};
export default Services;

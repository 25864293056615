import React from 'react';
import { PrivateSellerConnect } from '../../../components/PrivateSellerConnect';
import { setSessionItem } from '../../../utils/storage';
import { makeFinanceableSummaryTrackingEvent } from '../../../utils/trident';
import * as buyerAccountLoginUtils from '../../../utils/fsbo/buyerAccountLogin';

export const BDPPrivateConnect = ({userEmail, userRef, disableRequestInfo, handleOpenContactForm}) => {
  const handleLoginContactButtonClick = (event, actionLabel) => {

    event.preventDefault();
    const contactTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(contactTracking);
    if (!userEmail) {
      userRef?.current?.onOpenModal();
      setSessionItem(buyerAccountLoginUtils.getSessionKey(), true);
    } else {
      if (handleOpenContactForm) {
        handleOpenContactForm(event, { dataTestingClass: { suffix: 'contact-modal' } });
      }
    }
  };
  return (
    <>
      <div id="prequalified-and-contact-seller" className="info-container">
        <PrivateSellerConnect
          handleLoginContactButtonClick={handleLoginContactButtonClick}
          disableRequestInfo={disableRequestInfo}
        />
      </div>
    </>
  );
};

import React, {useContext, useRef, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {BDPContext} from '../MainContent/BoatDetailsPage';
import CollapsibleDetailsSection from '../DetailsSection/CollapsibleDetailsSection';
import { BoatDetails } from '@dmm/lib-react-ui-components';
import { getBoatDetails } from '../../../utils/listingHelper';
import BDPEmailLeadForm from './BDPEmailLeadForm';
import useIntersectionObserver from '../../../utils/hooks/useIntersectionObserver/useIntersectionObserver';
import { isFSBOorOEMListing } from '../../../utils/listingHelper';
import { showAdditionalLeadsModal } from '../../../store/actions';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import { GetPrequalifiedCTA } from '../FinanceableBoatSummary/GetPrequalifiedCTA';
import { BDPPrivateConnect } from './BDPPrivateConnect';
import { ContactCard } from '@dmm/lib-react-ui-components';
import { trackPhoneCall, addLead } from '../../../store/actions/dataLayer';

const BDPDetailsSection = ({
  isWorking, listing, myboats, cookies, url, detailsState,
  adParams, onClickRequestInformation, breakpoint,
  displayModalPreQualified, shouldShowFinanceSummary, onFormVisibilityChange,
  isPrivateSellerConnect, handleOpenContactForm, userEmail, userRef,
  serverAds,
  useServerAds
}) => {
  const { featureFlagBDPPhase4 } = useFeatureFlags();
  const {tridentValues, isLeadSubmitted} = useContext(BDPContext);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate} = tridentValues;

  const leadRef = useRef(null);
  const dispatch = useDispatch();
  const isFormVisible = useIntersectionObserver(leadRef, { threshold: 0.5 });
  const contactCardSrc = listing.owner?.logos?.default || '';
  const contactCardSellerName = listing.owner?.name;
  const contactCardPhoneNumber = listing.contact?.phone;
  const leadSubmitted = useSelector((state) => get(state, 'app.additionalLeads.open', false));

  useEffect(() => {
    if (onFormVisibilityChange) {
      onFormVisibilityChange(isFormVisible);
    }
  }, [isFormVisible, onFormVisibilityChange]);

  useEffect(() => {
    if (leadSubmitted === true) { setShowConfirmationMessage(true); }
  }, [leadSubmitted]);

  const handleClickPhoneCall = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(listing.Id, 'phone call'));
  };


  const isFSBOorOEM = isFSBOorOEMListing(listing);

  return (
    <div className="collapsible-details-section">
      {featureFlagBDPPhase4 && breakpoint === 'tablet' && !isPrivateSellerConnect &&
        <ContactCard
          src={contactCardSrc}
          listedByText="Listed by"
          sellerName={contactCardSellerName}
          phoneNumber={contactCardPhoneNumber || ''}
          showConfirmationMessage={showConfirmationMessage}
          handleClick={(e) => handleOpenContactForm(e, { dataTestingClass: { suffix: 'contact-modal' }})}
          onClickPhoneNumber={handleClickPhoneCall}
          buttonLabel="Contact Seller"
          buttonAttributes={{ 'data-testing-class': `contact-seller-${breakpoint}-contact-card` }}
          successMessage= "Congratulations! We have sent your information directly to the seller."
        />
      }

      {isPrivateSellerConnect &&  breakpoint === 'tablet' && featureFlagBDPPhase4 &&
        <div className="private-connect-wrapper" ref={leadRef}>
          <BDPPrivateConnect userEmail={userEmail} userRef={userRef} handleOpenContactForm={handleOpenContactForm}/>
        </div>
      }

      {(shouldShowFinanceSummary && breakpoint === 'tablet' && featureFlagBDPPhase4) &&
       <div className="pre-qualify-cta-wrapper"><GetPrequalifiedCTA listing={listing}/></div>
      }
      <div className="additional-boat-details">
        <BoatDetails details={getBoatDetails(listing)} />
      </div>

      {breakpoint === 'mobile'  && !isFSBOorOEM && featureFlagBDPPhase4 &&
        <div className="email-lead-form-container" ref={leadRef}>
          <BDPEmailLeadForm
            listing={listing}
            breakpoint={breakpoint}
            buttonAttributes={{ 'data-testing-class': `form-submission-${breakpoint}-bdp` }}
            onSuccess={(ld) => {
              dispatch(showAdditionalLeadsModal(ld));
            }}
          />
        </div>}

      {isPrivateSellerConnect &&  breakpoint === 'mobile' && featureFlagBDPPhase4 &&
        <div className="private-connect-wrapper" ref={leadRef}>
          <BDPPrivateConnect userEmail={userEmail} userRef={userRef} handleOpenContactForm={handleOpenContactForm}/>
        </div>
      }

      {(shouldShowFinanceSummary && breakpoint === 'mobile' && featureFlagBDPPhase4) &&
       <div className="pre-qualify-cta-wrapper"><GetPrequalifiedCTA listing={listing}/></div>
      }

      <CollapsibleDetailsSection
        url={url}
        myboats={myboats}
        cookies={cookies}
        listing={listing}
        adParams={adParams}
        isLoading={isWorking}
        breakpoint={breakpoint}
        isLeadSubmitted={isLeadSubmitted}
        tridentLoanAmount={tridentLoanAmount}
        tridentTeaserRate={tridentTeaserRate}
        tridentTermInMonths={tridentTermInMonths}
        showModalPreQualified={displayModalPreQualified}
        onClickRequestInformation={onClickRequestInformation}
        openPaymentCounter={detailsState.openPaymentCounter}
        openPaymentSection={detailsState.openPaymentSection}
        isMobile={breakpoint !== 'desktop'}
        serverAds={serverAds}
        useServerAds={useServerAds}
      />
    </div>
  );
};

export default BDPDetailsSection;

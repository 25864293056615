import ServerAd from '../../../components/ServerAd';
import classNames from 'classnames';
import React from 'react';
import './styles.css';

const CompanionServerAd = ({ ad, adKey }) => {
  const sticky = adKey.includes('sticky');

  return (
    <section className={classNames('companion-ad', { 'sticky': sticky })} id={`companion-ad-${adKey}`} data-e2e={`companion-ad-${adKey}`}>
      <ServerAd ad={ad} closeSelector={sticky ? '#companion-ad-home_companion-sticky-banner' : undefined} />
    </section>
  );
};

export default CompanionServerAd;

import classnames from 'classnames';
import get from 'lodash/get';
import React, { PureComponent, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withABTest } from '../../../server/abTest/withABTest';
import { showAdditionalLeadsModal, setLeadSuccess } from '../../../store/actions';
import { setOemBrandProductImpression } from '../../../store/actions/dataLayer';
import { getBreakpoint } from '../../../utils/commonHelper';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import BDPEmailLeadForm from '../BDPSections/BDPEmailLeadForm';
import StickyContact from '../StickyContact';
import './styles.css';

const ContactFormWrapper = ({
  open,
  forceOpen,
  listing,
  handleClose,
  contactFormPosition,
  showAdditionalLeadsModal,
  abTestConfiguration,
  dataTestingClass = {},
  userEmail,
  isPrivateSellerConnect
}) => {
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const isUserLoginExperiment = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);
  const initialValues = isUserLoginExperiment ? { email: userEmail } : null;
  const breakpoint = getBreakpoint();
  const dispatch = useDispatch();


  useEffect(() => {
    // Disable the email field for the experiment because there is not a an easy
    // way to disable the email field in the BDPEmailLeadForm
    if (isUserLoginExperiment && userEmail) {
      document.getElementById('modal-top-email')?.setAttribute('readonly', true);
    }
  }, [isUserLoginExperiment, userEmail, open]);

  const dataTestingClassPrefix = dataTestingClass.prefix || 'form-submission';
  const dataTestingClassSuffix = dataTestingClass.suffix || 'contact';
  const dynamicDataTestingClass = `${dataTestingClassPrefix}-${breakpoint}-${dataTestingClassSuffix}`;

  return <>
    <div className={classnames('contact-area', { open: (open || forceOpen) })}>
      {isUserLoginExperiment && isPrivateSellerConnect &&
        <div className="contact-instructions-title">
          <img alt="private seller connect logo" src={`${CDN_URL}/boat-services/private-seller-connect-logo.svg`}/>
        </div> }
      <button className="close-modal" onClick={handleClose}>
        <img width="18" height="18" alt="close icon" src={`${process.env.REACT_APP_CDN_URL}/img/icons/modal-close-button.svg`}/>
      </button>
      { (open || forceOpen) &&
        <BDPEmailLeadForm
          listing={listing}
          breakpoint={breakpoint}
          initialValues={initialValues}
          contactFormPosition={contactFormPosition}
          buttonAttributes={{ 'data-testing-class': dynamicDataTestingClass }}
          onSuccess={(ld) => {
            showAdditionalLeadsModal(ld);
            dispatch(setLeadSuccess(true));
            handleClose();
            return;
          }}
        />
      }
    </div>
  </>;
};

class DetailsContact extends PureComponent {

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
      searchConfig: get(JSON.parse(window.localStorage.getItem('nextPreviousData')), 'searchParams', {}),
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  setFadeWrapperRef = (node) => {
    window.fadeWrapperRef = [
      ...(window.fadeWrapperRef || []),
      node
    ];
  }

  handleClickOutside = (event) => {
    if (this.state.open && this.props.toggle && this.props.closeButtonFunc && this.wrapperRef && !this.wrapperRef.contains(event.target)){
      this.props.closeButtonFunc();
    }
    if (this.state.open && this.props.toggle && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        open: false
      });
    }
    if (window.fadeWrapperRef && !window.fadeWrapperRef.find((ref) => ref && ref.contains && ref.contains(event.target))) {
      this.handleModalFade();
    }
  }

  handleModalFade = () => {
    const modal = document.querySelector('.modal.fade');
    if (modal) {
      modal.classList.add('hide');
    }

  }

  state = {
    open: this.props.initialState === 'open',
    imageLoadError: false,
    searchConfig: {}
  }



  render() {
    const { isLoading, listing, disableSticky, id, showModalDealerLocator } = this.props;
    const showDealers = get(this.props, 'listing.oemDetails.oemDealerLocator', null);
    return (<>
      { showDealers && !disableSticky  &&
            <StickyContact
              isLoading={isLoading}
              listing={listing}
              isOpening={this.state.open === false}
              showDealers={showDealers}
              onOpenContact={showModalDealerLocator}
              fadeButtons={this.props.fadeButtons}
            />
      }
      {!showDealers &&
      <>

        <div className={classnames('details-contact', 'cta-modal', { open: this.state.open })} ref={this.setWrapperRef} id={id}>
          <button className="hidden" id="details-contact-anchor" onClick={() => this.handleClose()}>Details Contact</button>
          {!isLoading && this.renderContact(false, 'modal-top-')}
          { !disableSticky &&
            <>

              <StickyContact
                isLoading={isLoading}
                listing={listing}
                isOpening={this.state.open === false}
                onOpenContact={this.handleOpen}
                fadeButtons={this.props.fadeButtons}
              />
            </>
          }
        </div>
        <div className="modal hide fade">
          <div className="details-contact-modal">
            <div className="modal-content" ref={this.setFadeWrapperRef}>
              <div className="close-modal" data-e2e="close-modal-btn" onClick={this.handleModalFade.bind(this)}></div>
              <div className="modal-body">
                <div className={classnames('details-contact open')}>
                  {!isLoading && this.renderContact(true, 'modal-fade-')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      }

    </>);
  }

  renderContact(forceOpen, contactFormPosition) {
    const { listing, userEmail, abTestConfiguration, isPrivateSellerConnect } = this.props;
    const dataTestingClass = this.state.dataTestingClass || this.props.dataTestingClass;

    return <ContactFormWrapper
      open={(this.state.open || forceOpen)}
      forceOpen={forceOpen}
      listing={listing}
      handleClose={this.handleClose}
      contactFormPosition={contactFormPosition}
      showAdditionalLeadsModal={this.props.showAdditionalLeadsModal}
      abTestConfiguration={abTestConfiguration}
      dataTestingClass={dataTestingClass}
      userEmail={userEmail}
      isPrivateSellerConnect={isPrivateSellerConnect}
    />;
  }

  handleOpen = (e, UIEvent = {}) => {
    this.setState({
      open: this.props.toggle ? !this.state.open : true,
      ...UIEvent
    });
  }

  handleClose = () => {
    if (this.props.closeButtonFunc) {
      this.props.closeButtonFunc();
    }
    this.setState({
      open: false
    });
  }

  componentDidUpdate(prevProps){
    if (this.props.isLoading && this.state.open && this.props.initialState !== 'open'){
      this.setState({
        open: false
      });
    } else if (prevProps.externalOpen !== this.props.externalOpen) {
      this.setState({
        open: this.props.externalOpen
      });
    }
  }
}

export default withABTest(connect(
  null,
  dispatch => bindActionCreators({
    showAdditionalLeadsModal,
    setOemBrandProductImpression
  }, dispatch)
)(DetailsContact));

import { Action } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import get from 'lodash/get';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withABTest } from '../../../server/abTest/withABTest';
import { bindActionCreators } from 'redux';
import { addLead, trackContactFormOpen, trackPhoneCall, trackPhoneNumberReveal } from '../../../store/actions/dataLayer';
import { isFSBOContact } from '../../../utils/listingHelper';
import './styles.css';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import * as buyerAccountLoginUtils from '../../../utils/fsbo/buyerAccountLogin';
import { setSessionItem } from '../../../utils/storage';
import { makeFinanceableSummaryTrackingEvent, checkPreQualVariation, isFinanceable } from '../../../utils/trident';
import { getBreakpoint } from '../../../utils/commonHelper';
import { BOAT_DETAIL_CTA_LABEL } from '../../../constants/boatDetails';

class ContactGroup extends PureComponent {
  state = {
    revealPhoneNumber: false
  }
  render() {
    const contact = get(this.props, 'listing.contact');
    const phoneNumber = get(contact, 'phone');
    const dealerName = get(this.props, 'listing.owner.rootName') || get(this.props, 'listing.owner.name');
    const oemListing = get(this.props, 'listing.oemDetails', false);
    const showDealer = get(oemListing, 'oemDealerLocator');
    const actionLabel = BOAT_DETAIL_CTA_LABEL;
    const dataTestingClassSuffix = this.props.isSticky ? 'sticky' : 'gallery';
    const actionLabelSlug = actionLabel.toLowerCase().replace(/\s+/g, '-');
    const breakpoint = getBreakpoint();
    const price = get(this.props.listing, 'price.type.amount.USD');
    const financeable = isFinanceable(price, this.props.listing?.year);

    //I dont think this is needed we can just reuse the other one on line 70. Theres no diffrence just need to add the fsbo flag - Orlando BT-1933
    if (isFSBOContact(contact)) {
      return <div className={classnames('contact-group', { 'show-icons': this.props.showIcons })}>
        <div className="offered-by" data-e2e="offered-by">Offered By: <span className="dealer-name">{dealerName}</span></div>
        <div className="contact-buttons" data-e2e="contact-buttons">
          <Action
            attributes={{ 'data-testing-class': `${actionLabelSlug}-${breakpoint}-${dataTestingClassSuffix}` }}
            className="contact-button contact-button-fsbo"
            data-e2e="request-info-button"
            onClick={this.handleContactOpen}
            label={actionLabel}
            size="large"
          />
          {!oemListing && financeable && (
            <>
              <Action
                attributes={{ 'data-testing-class': `${actionLabelSlug}-${breakpoint}-${dataTestingClassSuffix}` }}
                className={classnames('call-button', {'hide': this.state.revealPhoneNumber})}s
                data-e2e="get-qualified-button"
                onClick={() => this.handleGetPreQualified(this.props.listing)}
                variant="secondary"
                label="Get Pre-Qualified"
                as="a"
              />
            </>
          )}
        </div>
      </div>;
    }

    return <div className={classnames('contact-group', { 'show-icons': this.props.showIcons })}>
      <div className="offered-by" data-e2e="offered-by">Offered By: <span className="dealer-name">{dealerName}</span></div>
      <div className="contact-buttons" data-e2e="contact-buttons">
        <Action
          attributes={{ 'data-testing-class': `${actionLabelSlug}-${breakpoint}-${dataTestingClassSuffix}` }}
          className={classnames('contact-button', { 'contact-button-oem': oemListing })}
          data-e2e="request-info-button"
          data-testid="request-info-btn"
          onClick={this.handleContactOpen}
          label={showDealer ? 'Find a Dealer Near You' : actionLabel}
        />
        {!oemListing && financeable && (
          <>
            <Action
              attributes={{ 'data-testing-class': `${actionLabelSlug}-${breakpoint}-${dataTestingClassSuffix}` }}
              className={classnames('call-button', {'hide': this.state.revealPhoneNumber})}s
              data-e2e="get-qualified-button"
              onClick={() => this.handleGetPreQualified(this.props.listing)}
              variant="secondary"
              label="Get Pre-Qualified"
              as="a"
            />
          </>
        )}
        {!oemListing && !financeable && (
          <>
            <Action
              attributes={{ 'data-testing-class': `${actionLabelSlug}-${breakpoint}-${dataTestingClassSuffix}` }}
              className={classnames('call-button', {'hide': this.state.revealPhoneNumber})}
              data-e2e="call-button"
              onClick={this.handlePhoneCall}
              href={`tel:${phoneNumber}`}
              variant="secondary"
              label="Call"
              as="a"
            />
            <div className={classnames('phone-number', {'hide': !this.state.revealPhoneNumber})} data-e2e={classnames('phone-number', {'hide': !this.state.revealPhoneNumber})}>Call: <span>{phoneNumber}</span></div>
          </>
        )}
      </div>
    </div>;
  }

  handleGetPreQualified = (listing) => {
    const preQualTracking = makeFinanceableSummaryTrackingEvent('pre-qualify sticky footer', 'click');
    window.dataLayer.push(preQualTracking);
    checkPreQualVariation({utmCampaign: 'BDPprequalhero', utmContent: 'BDPprequalapplyCTA', listing});
    this.props.dispatch({ type: 'GET_PRE_QUALIFIED', payload: listing });
  }

  handleContactOpen = (e) => {
    const oemListing = get(this.props, 'listing.oemDetails', {});
    if (oemListing.oemDealerLocator && this.props.openModalDealerLocator) {
      this.props.openModalDealerLocator();
    } else {
      const isUserLoginExp = buyerAccountLoginExperiment.isActive(this.props.abTestConfiguration, this.props.listing);
      const isFSBO = isFSBOContact(get(this.props, 'listing.contact'));
      if (isUserLoginExp && !this.props.userEmail && isFSBO) {
        const contactTracking = makeFinanceableSummaryTrackingEvent('contact seller carousel gallery', 'click');
        window.dataLayer.push(contactTracking);

        const sessionKey = buyerAccountLoginUtils.getSessionKey();
        setSessionItem(sessionKey, true);

        this.props.userRef?.current?.onOpenModal();
      } else {
        this.props.onOpenContact(e, {});
        if ((this.props.isOpening === undefined || this.props.isOpening) && !this.props.showDealers) {
          this.props.trackContactFormOpen();
        }
      }
    }
  }

  handlePhoneCall = () => {
    if (this.props.device !== 'desktop'){
      this.props.trackPhoneCall();
      this.props.addLead(get(this.props, 'listing.id'), 'phone lead');
    } else {
      this.props.trackPhoneNumberReveal();
      this.props.addLead(get(this.props, 'listing.id'), 'phone lead');
      this.setState({revealPhoneNumber: true});
    }
  }
}

export default withABTest(connect(
  state => ({
    userRef: get(state.app, 'userRef', {}),
    userEmail: get(state.app, 'userEmail')
  }),
  dispatch => ({
    dispatch,
    ...bindActionCreators({
      trackContactFormOpen,
      trackPhoneCall,
      trackPhoneNumberReveal,
      addLead
    }, dispatch)
  })
)(ContactGroup));

import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';
const CDN_URL = process.env.REACT_APP_CDN_URL;
const ContactSuccessNotification = () => {
  return <div className="inline-contact-success">
    <div className="inline-contact-success-info">
      <div className="inline-contact-title">Your email has been sent</div>
    </div>
    <div className="inline-contact-text">
        We have sent your email directly to the seller.
      <br />
      <b>Next, get pre-qualified and on the water faster.</b>
    </div>
  </div>;
};
export const PrivateSellerConnect = ({ disableRequestInfo, handleLoginContactButtonClick}) => {
  const showDetailsSuccess = useSelector((state) => state.app.leadSuccess);
  return (
    <>
      {(showDetailsSuccess || disableRequestInfo) && <ContactSuccessNotification/>}
      {!showDetailsSuccess &&
        <div className="contact-container">
          <div className="contact-instructions-title">
            <img alt="private seller connect logo" src={`${CDN_URL}/boat-services/private-seller-connect-logo.svg`}/>
          </div>
          <span id="for-sale-by-subtitle">For sale by Private Seller</span>
          {(!disableRequestInfo || !showDetailsSuccess) &&
              <button
                id="contact-seller-button"
                data-testid="contact-seller-button"
                className="contact-button"
                onClick={(e) => handleLoginContactButtonClick(e, 'contact seller')}
              >
                Contact Seller
              </button>
          }
          <div className="contact-instructions-description-container">
            <p>
              <b>Connect with confidence. </b>
              <span>Schedule time to view the boat and make an offer.</span>
              <br/>
              <br/>
              <b>Easy sale. </b>
              <span>Financing, closing services, warranty, and GAP insurance available.</span>
            </p>
          </div>
        </div>
      }
    </>
  );
};

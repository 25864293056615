import React from 'react';

import './styles.css';
import ListingResultV2 from '../ListingResultV2';
import { removeAdsForBrandedSrp, injectOemListingsRandomly } from '../../utils/listingHelper';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { trackContactFormOpen } from '../../store/actions/dataLayer';
import AlertMessage from '../../components/AlertMessage';
import { matchAdName } from '../../utils/ads/adsUtils';
import MobileLiner from '../Ads/MobileLiner';
import { bindActionCreators } from 'redux';
import {
  isPhoneResolution,
  isTabletOrSmallLaptopResolution,
  isLaptopResolution, toBoolean
} from '../../utils/commonHelper';
import ListingContactForm from '../ListingContactForm';
import { ListingsCarousel } from '../ListingsCarousel';
import {LISTING_SPONSORED, SPONSORED_TRACKING} from '../../constants/boats';
import { connect } from 'react-redux';
import SponsoredBoatElement from '../../containers/SearchResults/SponsoredBoats/SponsoredBoatElement';
import ServerAd from '../ServerAd';

/*
  This Component is only used for Boat Results.
*/
class ListingListV2 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      contactForm: {
        type: '',
        form: undefined
      },
      window: false,
      contactProps: null
    };

    this.updateContactProps = this.updateContactProps.bind(this);
  }

  componentDidMount() {
    this.setState({
      window: window
    });
  }

  addContactForm(contactForm) {
    this.setState({
      contactForm: contactForm
    });
  }

  closeForm() {
    const closedForm = {contactForm: {type: '', form: null}};
    this.setState(closedForm);
  }

  updateContactProps (newContactProps) {
    this.props.trackContactFormOpen();
    this.setState({
      contactForm: {
        form: newContactProps,
        type: 'contact'
      }
    });
  }

  render() {
    // let DESKTOP_AD_POSITION = 6;
    const MOBILE_AD_POSITION = 6;
    // const LEADERBOARD_ALPHA_POSITION = 2;
    let maxDesktopAds = 5;
    let maxMobileAds = 5;
    let itemCount = 0;
    let desktopAdCount = 0;
    let tabletSmallLaptopAdCount = 0;
    let mobileAdCount = 0;
    let {
      noAdsParam,
      myboats,
      cookies,
      url,
      pos,
      dealerId,
      tridentTeaserRate,
      ficoScores,
      isLeadSubmitted,
      oemList,
      numSponsoredListings,
      carouselSponsoredListings,
      isPayingBrand
    } = this.props;
    let listingArr;
    const listings = removeAdsForBrandedSrp(this.props.listings, this.props.isBrandedSrp);
    if (!this.props.isBrandedSrp && get(oemList, 'count', 0) > 0){
      injectOemListingsRandomly(listings, oemList.records);
    }
    if (this.props.isBrandedSrp) {
      maxDesktopAds = 2;
      maxMobileAds = 2;
    }

    const isPhoneRes = isPhoneResolution();
    const isTabletRes = isTabletOrSmallLaptopResolution();
    const formProps = this.state.contactForm?.form;
    const openForm = toBoolean(formProps);
    let carouselPosition;
    if (isPhoneRes){
      carouselPosition = 13; //phone position including top featured listings
    }
    else if (isTabletRes){
      carouselPosition = 15; //tablet position including top featured listings
    }
    else if (!noAdsParam){
      carouselPosition = 13; //Desktop position including top featured listings
    }
    else {
      carouselPosition = 15; //Desktop position including top featured listings
    }
    const sponsored = this.props.sponsored;

    const sponsoredBoats = sponsored?.records || [];
    listingArr = [];
    const usedAds = new Set();

    return (
      <ol className="boat-list">
        {
          sponsoredBoats.map((listing, index) => (
            <SponsoredBoatElement
              key={`sponsoredBoat-${listing.id}`}
              listing={listing}
              myboats={myboats}
              tridentTeaserRate={tridentTeaserRate}
              url={url}
              onRequestInfo={this.updateContactProps}
              position={index + 1}
            />
          ))
        }
        {/*
          You must use li elements inside ol or ul https://html.spec.whatwg.org/multipage/grouping-content.html#the-ol-element
          Putting ListingContactForm outside the list completely breaks styles, fix that at your own risk
          also, we are adding children to listings array that are not lis like div.tablet-ads-wrapper :'(
        */}
        <li className="boat-list-modal">
          <ListingContactForm open={openForm} onClose={() => this.closeForm()} {...formProps} />
        </li>
        {
          listings.map((listing) => {
            itemCount += 1;
            let position = {position: numSponsoredListings + itemCount, page: pos.page};
            const listingResultProperties = {
              listing: listing,
              position,
              myboats,
              cookies,
              url,
              itemCount,
              dealerId,
              dataLayer: this.props?.dataLayer
            };
            if (this.props.isBrandedSrp) {
              listingResultProperties.isBrandedSrp = this.props.isBrandedSrp;
            }
            if (this.props.isEngine) {
              listingResultProperties.isEngine = this.props.isEngine;
            }
            listingArr = [
              <ListingResultV2
                key={listing.id}
                addContactForm={(contactFormProps) => this.addContactForm(contactFormProps)}
                contactForm={this.state.contactForm}
                tridentTeaserRate={tridentTeaserRate}
                ficoScores={ficoScores}
                isLeadSubmitted={isLeadSubmitted}
                isBrandedSrp={this.props.isBrandedSrp}
                lazyLoading={false}
                {...listingResultProperties}
              />
            ];

            if (!noAdsParam) {

              // mobile ads
              if (this.state.window && isPhoneRes) {
                //mobile and desktop ad slots with adbutler are the same.
                if (itemCount % MOBILE_AD_POSITION === 0 && mobileAdCount < maxMobileAds) {
                  ++mobileAdCount;
                  if (!isEmpty(matchAdName(this.props.serverAds, `boat-search_inline-box-${mobileAdCount}`))) {
                    listingArr.push(<>
                      <ServerAd
                        ad={matchAdName(this.props.serverAds, `boat-search_inline-box-${mobileAdCount}`)}
                        className="mobile-liner server-ad"
                      />
                    </>);
                  } else {
                    listingArr.push(<>
                      <MobileLiner key={`mobAd${listing.id}`} adNum={mobileAdCount}/>
                    </>);
                  }
                }
              }

              // Tablet ads
              let tabletSmallLaptopAdCondition;
              if (tabletSmallLaptopAdCount === 0) {
                tabletSmallLaptopAdCondition = itemCount === 3;
              } else {
                tabletSmallLaptopAdCondition = itemCount === 12;
              }
              if (tabletSmallLaptopAdCondition && tabletSmallLaptopAdCount < maxDesktopAds) {
                tabletSmallLaptopAdCount++;
                if (this.state.window && isTabletOrSmallLaptopResolution()) {
                  if (!isEmpty(matchAdName(this.props.serverAds, `boat-search_inline-box-${tabletSmallLaptopAdCount}`))) {
                    listingArr.push(
                      <div key={`div1-${listing.id}`} className={'tablet-ads-wrapper lib-card wrapper-card--ad'}>
                        <ServerAd
                          ad={matchAdName(this.props.serverAds, `boat-search_inline-box-${tabletSmallLaptopAdCount}`)}
                          className="lib-card lib-card--ad tablet server-ad"
                        />
                      </div>
                    );
                  }
                }
                tabletSmallLaptopAdCount++;
                if (this.state.window && isTabletOrSmallLaptopResolution()) {
                  if (!isEmpty(matchAdName(this.props.serverAds, `boat-search_inline-box-${tabletSmallLaptopAdCount}`))) {
                    listingArr.push(
                      <div key={`div2-${listing.id}`} className={'tablet-ads-wrapper lib-card wrapper-card--ad'}>
                        <ServerAd
                          ad={matchAdName(this.props.serverAds, `boat-search_inline-box-${tabletSmallLaptopAdCount}`)}
                          className="lib-card lib-card--ad tablet server-ad"
                        />
                      </div>
                    );
                  }
                }
              }

              // Desktop ads
              // desktop (screens bigger than 1320px)
              // first ad positioned after 4th listing and then every 5th listing after that
              let desktopAdCondition;
              if (desktopAdCount === 0) {
                desktopAdCondition = itemCount === 4;
              } else {
                desktopAdCondition = (itemCount - 4) % 5 === 0;
              }

              if (desktopAdCondition && desktopAdCount < maxDesktopAds) {
                desktopAdCount++;
                if (this.state.window && isLaptopResolution()) {
                  for (let i = desktopAdCount; i <= maxDesktopAds; i++) {
                    const ad = matchAdName(this.props.serverAds, `boat-search_inline-box-${i}`);
                    if (!isEmpty(ad) && !usedAds.has(ad.key)) {
                      listingArr.push(
                        <div data-id={ad.key} key={`wrapper${listing.id}`} className={'desktop-ads-wrapper lib-card wrapper-card--ad'}>
                          <ServerAd
                            ad={ad}
                            className="lib-card lib-card--ad desktop server-ad"
                          />
                        </div>
                      );
                      usedAds.add(ad.key);
                      break;
                    }
                  }
                }
              }
            }

            const showSponsoredCarousel = !this.props.isBrandedSrp && !dealerId && !isPayingBrand;
            if (showSponsoredCarousel && (position.position === carouselPosition || listings.length === itemCount && listings.length + numSponsoredListings < carouselPosition)){
              listingArr.push(
                <ListingsCarousel
                  key="sponsoredListingsCarousel"
                  listingsType={LISTING_SPONSORED}
                  listingTrackingClick={SPONSORED_TRACKING}
                  listings={carouselSponsoredListings}
                  title="Sponsored Boats"
                  subtitle="Related to your search"
                  page={position.page}
                ></ListingsCarousel>
              );
            }

            if (listing.firstOemModel) {
              listingArr.push(<AlertMessage key={`alert${listing.id}`} mainMessage="Below are a few similar boat listings we think you might like." />);
            }

            return listingArr;
          }).reduce((prev, next) => [...prev, ...next], [])
        }
      </ol>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sponsored: state.app.data.sponsored,
    contactProps: state.app.contactProps
  };
};

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators({
    trackContactFormOpen,
  }, dispatch)
)(ListingListV2);

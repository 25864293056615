import React, {useState} from 'react';
import classNames from 'classnames';
import Tooltip from 'react-tooltip-lite';
import { makeFinanceableSummaryTrackingEvent } from '../../utils/trident';
import { scrollTo } from '../../utils/scrollTo';
import { DOWN_PAYMENT_PERCENTAGE } from '../../constants/BoatLoans';
import './styles.css';
export const CustomizepaymentToolTip = ({monthlyPrice, tridentTeaserRate, tridentTermInMonths, onClickPaymentLink}) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [paymentCalculatorCounter, setPaymentCalculatorCounter] = useState(1);
  const CDN_URL = process.env.REACT_APP_CDN_URL;
  const toggleTip = () => {
    setToolTipOpen(!toolTipOpen);
  };
  const handleCustomizeLinkClick = (event) => {
    event.preventDefault();
    scrollTo('#payment-calculator-anchor', { behavior: 'smooth' });
    const customizeTracking = makeFinanceableSummaryTrackingEvent('customize', 'click');
    window?.dataLayer?.push(customizeTracking);
    onClickPaymentLink(paymentCalculatorCounter);
    setPaymentCalculatorCounter(prevCounter => prevCounter + 1);
  };
  return (
    <div className="boat-payment-container">
      <div className="payment-month">
        {`Own this boat for $${monthlyPrice}/mo`}
      </div>
      <div className="payment-tooltip">
        <Tooltip
          content={
            <div
              role="tooltip"
              className={classNames('financeable-boat-summary-monthly-price-tooltip-info', { 'hidden-tooltip': !toolTipOpen })}>
              {`Estimated monthly payment based on a ${tridentTermInMonths}-month loan at ${tridentTeaserRate}%
              APR with ${DOWN_PAYMENT_PERCENTAGE}% down payment`}
            </div>
          }
          useHover={false}
          isOpen={toolTipOpen}
          eventToggle="onClick"
          className="monthly-payment-tooltip-wrapper"
          direction="up"
          distance={10}
          background="#FFFFFF"
        >
          <img
            role="icon"
            className="info-icon"
            width="14" height="14"
            alt="info icon"
            src={`${CDN_URL}/boat-loans/finance-variant/Info.svg`}
            onClick={toggleTip}
          ></img>
        </Tooltip>
      </div>
      <div
        className="customize-link"
        onClick={handleCustomizeLinkClick}>Customize</div>
    </div>
  );
};

import React, { useContext } from 'react';
import BoatSummary from '../../../components/BoatSummary';
import { BDPContext } from '../MainContent/BoatDetailsPage';
import { GetPrequalifiedCTA } from '../FinanceableBoatSummary/GetPrequalifiedCTA';

const BDPBoatSummaryComponent = ({
  breakpoint, isWorking,summaryHandlers, handleOpenContactForm,
  tridentLoanAmount, tridentTeaserRate,
  tridentTermInMonths, showGetPrequalifiedCTA, userRef, userEmail, isPrivateSellerConnect, openModalPostLead, showSellerInfoCard, }) => {


  const {bannerText,listing} = useContext(BDPContext);
  const {
    handleSummaryPaymentLinkClicked,
    handleSummaryLocationLinkClicked
  } = summaryHandlers;

  return (
    <div className="boat-summary" data-testid="boat-summary" data-e2e="boat-summary">
      <BoatSummary
        breakpoint={breakpoint}
        isLoading={isWorking}
        listing={listing}
        onClickPaymentLink={handleSummaryPaymentLinkClicked}
        onClickLocationLink={handleSummaryLocationLinkClicked}
        handleOpenContactForm={handleOpenContactForm}
        bannerText={bannerText}
        tridentTermInMonths={tridentTermInMonths}
        tridentLoanAmount={tridentLoanAmount}
        tridentTeaserRate={tridentTeaserRate}
        userEmail={userEmail}
        userRef={userRef}
        isPrivateSellerConnect={isPrivateSellerConnect}
        openModalPostLead={openModalPostLead}
        showSellerInfoCard={showSellerInfoCard}
      />
      {!isWorking && showGetPrequalifiedCTA  && <GetPrequalifiedCTA listing={listing}/>}
    </div>
  );
};

const BDPBoatSummary = (props) => {
  return props?.show ? <BDPBoatSummaryComponent {...props}  /> : null;
};
export default BDPBoatSummary;

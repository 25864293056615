import React from 'react';
import './styles.css';
import { Calculator } from '../Calculator';

export const RatesCalculatorSection = ({ dataTestId = 'rates-calculator-section-component' }) => {
  return (
    <div className="rates-calculator-container" data-testid={dataTestId}>
      <Calculator />
    </div>
  );
};
